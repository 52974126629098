import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
// eslint-disable-next-line import/no-cycle

export default function useAppartements() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const appartement = ref([])
  const loader = ref(false)
  const appartementProcess = ref(false)
  const appartementSuccess = ref(false)

  const appartements = ref([])
  const errors = ref('')

  const getAllAppartements = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-appartements')
      if (response.data.success === true) {
        loader.value = false
        appartements.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getAppartementById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-appartement-by-id/${id}`)
      if (response.data.success === true) {
        appartement.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const deleteAppartement = async id => {
    try {
      errors.value = ''
      appartementProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.delete(`/delete-appartement/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)

        appartementSuccess.value = true
        appartementProcess.value = false
        await getAllAppartements()
      }
    } catch (error) {
      appartementProcess.value = false
      appartementSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const createAppartement = async data => {
    try {
      errors.value = ''
      appartementProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-appartement', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        appartementSuccess.value = true
        appartementProcess.value = false
        appartement.value = response.data.data
        await getAllAppartements()
      }
    } catch (error) {
      appartementProcess.value = false
      appartementSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateAppartement = async data => {
    try {
      errors.value = ''
      appartementProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-appartement/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        appartementSuccess.value = true
        appartementProcess.value = false
        appartement.value = response.data.data
        await getAllAppartements()
      }
    } catch (error) {
      appartementProcess.value = false
      appartementSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    errors,
    appartement,
    appartements,
    appartementProcess,
    loader,
    appartementSuccess,
    getAllAppartements,
    getAppartementById,
    createAppartement,
    updateAppartement,
    deleteAppartement,

  }
}
