<template>
  <b-row>
    <b-col md="12">

      <!-- Login v1 -->
      <b-overlay
        :show="loader"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-lg
        rounded="sm"
      >

        <b-card
          border-variant="info"
          header="Modifier un immbleuble"
          header-bg-variant="light-info"
          header-text-variant="white"
          header-tag="header"
          header-class="h2 "
          class="mb-0 mb-5"
        >
          <validation-observer
            #default="{ invalid }"
            ref="updateImmeubleRules"
          >
            <b-form>
              <b-row class="justify-content-between mt-2">
                <b-col md="12">
                  <b-card title="Géolocatisation">
                    <l-map
                      ref="map"
                      :zoom="zoom"
                      :center="userLocation|| position"
                      :options="mapOptions"
                      style="height: 255px"
                      @click="onMapClick"
                    >
                      <l-geosearch :options="geoSearchOptions" />

                      <l-tile-layer
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                      />
                      <l-circle
                        v-if="position.lat && position.lng"
                        :lat-lng.sync="userLocation"
                        :radius="circle.radius"
                        :color="circle.color"
                      />
                      <l-control-attribution
                        :position="attributionPosition"
                        :prefix="attributionPrefix"
                      />

                      <l-marker
                        v-if="position.lat && position.lng"
                        visible
                        :icon="iconT"
                        draggable
                        :lat-lng.sync="position"
                        @dragstart="dragging = true"
                        @dragend="dragging = false"
                      >
                        <l-tooltip
                          :content="tooltipContent"
                          :options="{ permanent: false }"
                        />
                      </l-marker>
                    </l-map>
                  </b-card>
                </b-col>
                <b-col
                  md="4"
                  class="shadow-lg ml-2 py-2 mb-2"
                >
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Nom de l'immeuble"
                        label-for="name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="nom de l'immeuble"
                          rules="required"
                        >
                          <b-form-input
                            id="name"
                            v-model="form.name"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom de l'immeuble"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group
                        label="Cette propriété a-t-elle plusieurs propriétaires?"
                        label-for="is_multiple_proprietaire"
                      >  <validation-provider
                        #default="{ errors }"
                        name="proprietaire unique"
                        rules="required"
                      >
                        <div class="d-flex">

                          <b-form-radio
                            v-model="form.is_multiple_proprietaire"
                            plain
                            name="is_multiple_proprietaire"
                            value="true"
                            class="mr-4"
                          >
                            Oui
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.is_multiple_proprietaire"
                            plain
                            name="is_multiple_proprietaire"
                            value="false"
                          >
                            Non
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        v-if="form.is_multiple_proprietaire==='false'"
                        label="Sélectionner le propriétaire"
                        label-for="proprietaire_actuel"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="propriétaire"
                          rules="required"
                        >
                          <v-select
                            id="proprietaire_actuel"
                            v-model="form.proprietaire_actuel"
                            label="name"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            :options="users"
                            placeholder="Sélectionner le propriétaire"
                            :reduce="(users) => users.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="form.is_multiple_proprietaire==='false'"
                      cols="12"
                    >
                      <b-form-group
                        label="Type de résidence"
                        label-for="residence"
                      >  <validation-provider
                        #default="{ errors }"
                        name="residence"
                        rules="required"
                      >
                        <div class="d-flex">

                          <b-form-radio
                            v-model="form.residence"
                            plain
                            name="residence"
                            value="Privée"
                            class="mr-4"
                          >
                            Privée
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.residence"
                            plain
                            name="residence"
                            value="En location"
                          >
                            En location
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Image"
                        label-for="image"
                        label-size="sm"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="file_name"
                          rules="required"
                        >
                          <b-form-file
                            id="file_name"
                            ref="fileInput"
                            v-model="form.file_name"
                            accept="image/*"
                            size="sm"
                            placeholder="Choisir un fichier"
                            @change="handleImageSelected"
                          >
                            <template
                              slot="file-name"
                              slot-scope="{ names }"
                            >
                              <b-badge variant="primary">
                                {{ names[0] }}
                              </b-badge>
                              <b-badge
                                v-if="names.length > 1"
                                variant="dark"
                                class="ml-1"
                              >
                                + {{ names.length - 1 }} More files
                              </b-badge>
                            </template>
                          </b-form-file>
                          <small class="text-danger">{{ errors[0] }}</small>
                          <b-button
                            v-if="uploadProcess"
                            variant="success"
                            disabled
                            block
                            size="sm"
                            class="mt-1"
                          >
                            <b-spinner small />
                            Chargement...
                          </b-button>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>
                <b-col
                  md="7"
                  class="shadow-lg mr-2"
                >
                  <!-- button -->
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-appartement
                    variant="gradient-info"
                    size="sm"
                    class="my-2 float-right"
                  >
                    Ajouter un appartement
                  </b-button>

                  <b-table
                    :fields="fields"
                    small
                    responsive="sm"
                    show-empty
                    empty-text="Aucun résultat à afficher"
                    :items="items"
                  >
                    <template #cell(actions)="data">

                      <feather-icon
                        v-b-modal.modal-image
                        class="text-primary ml-2"
                        icon="EyeIcon"
                        @click="getImage(data)"
                      />
                      <feather-icon
                        v-b-modal.modal-appartement
                        class="text-warning ml-2"
                        icon="EditIcon"
                        @click="getItem(data.item)"
                      />

                      <feather-icon
                        class="text-danger ml-2"
                        icon="TrashIcon"
                        @click="removeItem(data)"
                      />
                    </template>
                  </b-table>

                </b-col>
                <b-col md="12">
                  <!-- button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                    size="sm"
                    class="my-2 float-right ml-2"
                    :to="{ name: 'espace-coproprietaire.proprietes', replace: true }"
                  >
                    Annuler
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="info"
                    size="sm"
                    :disabled="invalid || proprieteProcess"
                    class="my-2 float-right"
                    @click.prevent="handleUpdateImmeubleSubmit"
                  >
                    Modifier
                    <b-spinner
                      v-if="proprieteProcess"
                      variant="light"
                      class="ml-1"
                      type="grow"
                      small
                    />
                  </b-button>
                </b-col>

              </b-row>
            </b-form>
          </validation-observer>

        </b-card>

      </b-overlay>
      <b-modal
        id="modal-image"
        ref="myModal"
        button-size="sm"
        title="Image appartement"
        ok-only
        ok-title="Fermer"
      >
        <b-card
          :img-src="imageUrl"
          img-top
          img-alt=""
          class="position-static"
        />

      </b-modal>
      <!-- modal -->
      <b-modal
        id="modal-appartement"
        ref="myModal"
        no-close-on-backdrop
        button-size="sm"
        :modal-class="appartementForm.id === null ? 'modal-success' : 'modal-warning'"
        :title="appartementForm.id===null?'Ajouter un appartement':'Modifier un appartement'"
        :ok-title="appartementForm.id === null ? 'Ajouter' : 'Modifier'"
        cancel-title="Fermer"
        cancel-variant="gradient-danger"
        :ok-variant="appartementForm.id===null?'gradient-success':'gradient-warning'"
        @hidden="resetAppartementForm"
        @ok="submitAppartementForm"
      >
        <validation-observer ref="appartementRules">
          <form @submit.prevent="handleAppartementSubmitModal">
            <b-form-group
              label="Nom de l'immeuble"
              label-for="libelle"
            >
              <validation-provider
                #default="{ errors }"
                name="libellé"
                rules="required"
              >
                <b-form-input
                  id="libelle"
                  v-model="appartementForm.libelle"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="form.is_multiple_proprietaire==='true'"
              label="Sélectionner le propriétaire"
              label-for="proprietaire_actuel"
            >
              <validation-provider
                #default="{ errors }"
                name="propriétaire"
                rules="required"
              >
                <v-select
                  id="proprietaire_actuel"
                  v-model="appartementForm.proprietaire_actuel"
                  label="name"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  :options="users"
                  placeholder="Sélectionner le propriétaire"
                  :reduce="(users) => users.id"
                  class="select-size-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="form.is_multiple_proprietaire==='true'"
              label="Type de résidence"
              label-for="residence"
            >  <validation-provider
              #default="{ errors }"
              name="residence"
              rules="required"
            >
              <div class="d-flex">

                <b-form-radio
                  v-model="appartementForm.residence"
                  plain
                  name="residence"
                  value="Privée"
                  class="mr-4"
                >
                  Privée
                </b-form-radio>
                <b-form-radio
                  v-model="appartementForm.residence"
                  plain
                  name="residence"
                  value="En location"
                >
                  En location
                </b-form-radio>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="form.residence === 'En location' || appartementForm.residence==='En location'"
              label="Loyer"
              label-for="loyer"
            >
              <validation-provider
                #default="{ errors }"
                name="loyer"
                rules="required"
              >
                <b-form-input
                  id="loyer"
                  v-model="appartementForm.loyer"
                  size="sm"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Loyer"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Adresse"
              label-for="adresse"
            >
              <validation-provider
                #default="{ errors }"
                name="adresse"
                rules="required"
              >
                <b-form-input
                  id="adresse"
                  v-model="appartementForm.adresse"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Superficie"
              label-for="superficie"
            >
              <validation-provider
                #default="{ errors }"
                name="superficie"
                rules="required"
              >
                <b-form-input
                  id="superficie"
                  v-model="appartementForm.superficie"
                  size="sm"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Superficie"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Image"
              label-for="fichier"
              label-size="sm"
            >
              <validation-provider
                #default="{ errors }"
                name="image"
                rules="required"
              >
                <b-form-file
                  id="fichier"
                  ref="fileInput"
                  v-model="appartementForm.file_name"
                  accept="image/*"
                  size="sm"
                  placeholder="Choisir un fichier"
                  @change="handleImageSelected"
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                    <b-badge
                      v-if="names.length > 1"
                      variant="dark"
                      class="ml-1"
                    >
                      + {{ names.length - 1 }} More files
                    </b-badge>
                  </template>
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
                <b-button
                  v-if="uploadProcess"
                  variant="success"
                  disabled
                  block
                  size="sm"
                  class="mt-1"
                >
                  <b-spinner small />
                  Chargement...
                </b-button>
              </validation-provider>
            </b-form-group>

          </form>
        </validation-observer>
      </b-modal>

      <!-- /Login v1 -->
    </b-col>
  </b-row>
</template>

<script>

import {
  ref, reactive, onMounted, computed, watch,
} from '@vue/composition-api'
import {
  LMap, LTileLayer, LMarker, LControlAttribution, LCircle, LTooltip,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, image, mimes,
} from '@validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BBadge,
  BCard,
  BFormFile,
  BForm,
  BSpinner,
  BButton, BModal, VBModal, BTable, BFormRadio,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/composables/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import useGestionCoproprietes from '@/composables/gestionCoproprieteService'
// eslint-disable-next-line import/no-cycle
import useAppartements from '@/composables/appartementService'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
import { Icon, icon } from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'
import Swal from 'sweetalert2'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    BTable,
    ValidationProvider,
    ValidationObserver,
    BOverlay,

    BCard,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    vSelect,
    BButton,
    BSpinner,
    BModal,

    LMap,
    LTileLayer,
    LControlAttribution,
    LCircle,
    LGeosearch,
    LMarker,
    LTooltip,
    BFormRadio,

    // eslint-disable-next-line vue/no-unused-components

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const user = JSON.parse(store.state.authStore.user)
    const { errorSweetAlert } = useSweetAlertNotification()

    const {
      imageUrl, imageFile, handleImageSelected, deleteFile, uploadFile, fileName, uploadProcess,
    } = useImageUpload()
    const {
      updatePropriete, proprieteSuccess, proprieteProcess, getProprieteById, propriete,
    } = useProprietes()
    const {
      getCoproprieteCoproprietaires, coproprietaires,
    } = useGestionCoproprietes()
    const {
      createAppartement, updateAppartement, appartementSuccess, deleteAppartement,
    } = useAppartements()
    const position = ref({})

    const fields = reactive([
      { key: 'libelle', label: 'Libellé' },
      { key: 'superficie', label: 'Superficie' },
      { key: 'loyer', label: 'Loyer' },
      { key: 'actions' },
    ])
    const appartementRules = ref('')
    const myModal = ref('')
    const updateImmeubleRules = ref('')
    const fileInput = ref('')

    // variable à enlever
    const loader = ref(false)

    const items = ref([])

    const getImage = data => {
      console.clear()
      imageUrl.value = data.item.propriete_image.image_url
    }

    const form = reactive({
      id: null,
      type_propriete_id: 1,
      name: '',
      copropriete_id: user.current_coownership,
      proprietaire_actuel: '',
      latitude: '',
      longitude: '',
      is_multiple_proprietaire: null,
      residence: null,
      hidden_location: null,
      appartements: [],
    })
    const appartementForm = reactive({
      id: null,
      libelle: '',
      superficie: '',
      propriete_id: router.currentRoute.params.id,
      loyer: '',
      image: '',
      file_name: null,
      residence: null,
      adresse: '',
    })

    const oldImage = ref(null)

    // Reset Form
    const resetAppartementForm = () => {
      appartementForm.id = null
      appartementForm.libelle = ''
      appartementForm.superficie = ''
      appartementForm.loyer = ''
      appartementForm.image = null
      appartementForm.file_name = ''
      appartementForm.adresse = ''
      imageUrl.value = ''
      fileName.value = ''
    }
    const users = ref([])
    const resetForm = () => {
      form.id = null
      form.name = ''
      form.proprietaire_actuel = ''
      form.appartements = []
      form.latitude = ''
      form.longitude = ''
    }
    const upload = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await uploadFile(data)
        appartementForm.image = fileName.value
        if (oldImage.value !== null) await deleteFile(oldImage.value, 'uploads')
      }
    }
    const getItem = async item => {
      appartementForm.id = item.id
      appartementForm.libelle = item.libelle
      appartementForm.superficie = item.superficie
      appartementForm.loyer = item.loyer
      oldImage.value = item.propriete_image.image
      appartementForm.image = item.propriete_image.image
      appartementForm.file_name = item.propriete_image.image
      appartementForm.adresse = item.adresse
      console.clear()
    }
    const handleUpdateImmeubleSubmit = () => new Promise((resolve, reject) => {
      if (form.is_multiple_proprietaire === 'true') {
        // eslint-disable-next-line no-unused-expressions
        form.is_multiple_proprietaire = true
      }
      if (form.is_multiple_proprietaire === 'false') {
        // eslint-disable-next-line no-unused-expressions
        form.is_multiple_proprietaire = false
      }
      updateImmeubleRules.value.validate().then(async success => {
        if (success) {
          if (form.appartements.length === 0) {
            reject()
            errorSweetAlert('Oups! Erreur', 'Vous devez ajouter au moins un appartement.')
          } else {
            resolve(true)
            const { lat, lng } = position.value
            form.latitude = lat
            form.longitude = lng
            await updatePropriete({ ...form })
            if (proprieteSuccess.value) router.push({ name: 'espace-coproprietaire.proprietes', replace: true })
          }
        }
      })
    })
    const handleAppartementSubmitModal = () => {
      appartementForm.proprietaire_actuel = form.proprietaire_actuel
      appartementForm.residence = form.residence

      if (form.is_multiple_proprietaire === 'true') {
        // eslint-disable-next-line no-unused-expressions
        appartementForm.is_multiple_proprietaire = true
      }
      if (form.is_multiple_proprietaire === 'false') {
        // eslint-disable-next-line no-unused-expressions
        appartementForm.is_multiple_proprietaire = false
      }
      appartementRules.value.validate().then(async success => {
        if (success) {
          await upload()
          if (appartementForm.id === null) await createAppartement({ ...appartementForm })
          else await updateAppartement({ ...appartementForm })
          await getProprieteById(form.id)
          items.value = propriete.value.appartements
          if (appartementSuccess.value) {
            myModal.value.toggle('#toggle-btn')
            fileInput.value.reset()
            resetAppartementForm()
          }
        }
      })
    }
    const removeItem = async data => {
      Swal.fire({
        title: 'Suppression',
        text: 'Etes-vous sûr de vouloir supprimer cet appartement ?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Supprimer',
      }).then(async result => {
        if (result.isConfirmed) {
          await deleteAppartement(data.item.id)
          await getProprieteById(form.id)
          items.value = propriete.value.appartements

          Swal.fire({

            title: 'Supprimé!',
            text: 'Appartement supprimé avec succès.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }
    //  Fonction de soumission du modal
    const submitAppartementForm = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleAppartementSubmitModal()
    }

    const zoom = ref(10)
    const loading = ref(false)
    const attributionPrefix = ref('Logis Connect')
    const attributionPosition = ref('bottomright')
    const userLocation = reactive({
      lat: null,
      lng: null,
    })
    const map = ref('')
    const mapOptions = reactive({
      zoomControl: true,
      attributionControl: false,
      zoomSnap: true,
    })
    const geoSearchOptions = reactive({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      autoClose: true,
    })
    const circle = reactive({
      radius: 300,
      color: '#EA5455',
    })
    const tileProvider = ref({
      name: 'OpenStreetMap',
      visible: true,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    })
    const iconT = icon({
      // eslint-disable-next-line global-require
      iconUrl: require('@/assets/images/location.gif'),
      iconSize: [40, 80],
      iconAnchor: [16, 37],
    })

    const dragging = ref(false)
    const address = ref(null)

    const getUserPosition = async () => {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          userLocation.lat = pos.coords.latitude
          userLocation.lng = pos.coords.longitude
        })
      }
    }
    const getAddress = async () => {
      loading.value = true
      address.value = 'Unresolved address'
      try {
        const { lat, lng } = position.value
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`,
        )
        if (result.status === 200) {
          const body = await result.json()
          address.value = body.display_name
        }
      } catch (e) {
        console.error('Reverse Geocode Error->', e)
      }
      loading.value = false
    }
    const onMapClick = value => {
      // place the marker on the clicked spot
      position.value = value.latlng
    }
    const onSearch = value => {
      const loc = value.location
      position.value = { lat: loc.y, lng: loc.x }
    }

    // eslint-disable-next-line no-nested-ternary
    const tooltipContent = computed(() => (dragging.value ? '...' : loading.value ? 'Loading...' : address.value !== null ? `<strong>${address.value.replace(
      ',', '<br/>',
    )}</strong> <hr/><strong>lat:</strong> ${position.value.lat
    }<br/> <strong>lng:</strong> ${position.value.lng}` : ''))

    watch(() => (position.value), async value => {
      position.value = value
      await getAddress()
    })
    onMounted(async () => {
      await getUserPosition()
      // eslint-disable-next-line no-prototype-builtins
      if (router.currentRoute.params.hasOwnProperty('id')) {
        form.id = router.currentRoute.params.id
        await getProprieteById(form.id)
        form.copropriete_id = propriete.value.copropriete_id
        form.proprietaire_actuel = +propriete.value.proprietaire_actuel.id
        form.type_propriete_id = propriete.value.type_propriete_id
        form.name = propriete.value.name
        form.is_multiple_proprietaire = propriete.value.is_multiple_proprietaire
        form.hidden_location = propriete.value.hidden_location

        form.residence = propriete.value.residence
        form.image = propriete.value.immeuble_image.image
        form.file_name = propriete.value.immeuble_image.image

        if (propriete.value.is_multiple_proprietaire === 0) {
          form.is_multiple_proprietaire = 'false'
        }
        if (propriete.value.is_multiple_proprietaire === 1) {
          form.is_multiple_proprietaire = 'true'
        }

        form.latitude = propriete.value.latitude
        form.longitude = propriete.value.longitude
        position.value = { lat: propriete.value.latitude, lng: propriete.value.longitude }

        form.appartements = propriete.value.appartements
        items.value = propriete.value.appartements
      }
      await getCoproprieteCoproprietaires(user.current_coownership)
      users.value = coproprietaires.value.users
      map.value.mapObject.on('geosearch/showlocation', onSearch)
      console.clear()
    })
    return {
      required,
      image,
      dragging,
      handleAppartementSubmitModal,
      handleUpdateImmeubleSubmit,
      appartementRules,
      mimes,
      submitAppartementForm,
      myModal,
      appartementForm,
      users,
      map,
      form,
      updateImmeubleRules,
      items,
      fields,
      getImage,
      resetAppartementForm,
      resetForm,
      imageUrl,
      loader,
      getItem,
      fileInput,
      uploadProcess,
      proprieteProcess,
      removeItem,
      handleImageSelected,

      zoom,
      iconT,
      geoSearchOptions,
      attributionPrefix,
      attributionPosition,
      mapOptions,
      userLocation,
      circle,
      tileProvider,
      address,
      getAddress,
      onMapClick,
      onSearch,
      tooltipContent,
      position,

    }
  },

}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/map-leaflet.scss';

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.leaflet-control-geosearch form input {
  min-width: 311px;
}
</style>
